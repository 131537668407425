/* MAIN CORRECTION STYLES */

 /* Дополнить и систематизировать */

.relative{position:relative !important}
.absolute{position:absolute !important}
.strong{font-weight:bold !important}
.nowrap{white-space: nowrap}
.hidden{display:none}
.absolute-bottom-right{
    position:absolute;
    bottom:2px;
    right:2px;
}
.float-left{float:left !important}
.float-right{float:right !important}
.float-none{float:none !important}
.display-block{display:block !important;}
.top-0{top:0 !important}
.alert-slim {padding: .5rem;}

/* --- Margins --- */
.m-0{margin:0!important}
.m-1{margin:1px!important}
.m-2{margin:2px!important}
.m-5{margin:5px!important}
.m-6{margin:6px!important}
.m-10{margin:10px!important}
.m-20{margin:20px!important}

.ml-0{margin-left:0!important}
.ml-3{margin-left:3px!important}
.ml-5{margin-left:5px!important}
.ml-10{margin-left:10px!important}
.ml-15{margin-left:15px!important}
.ml-20{margin-left:20px!important}
.ml-30{margin-left:30px!important}
.ml-40{margin-left:40px!important}
.ml-45{margin-left:45px!important}
.ml-50{margin-left:50px!important}
.ml-60{margin-left:60px!important}
.ml-80{margin-left:80px!important}
.ml-90{margin-left:90px!important}
.ml-100{margin-left:100px!important}
.ml-120{margin-left:120px!important}
.ml-140{margin-left:140px!important}
.ml-150{margin-left:150px!important}
.ml-160{margin-left:160px!important}
.ml-170{margin-left:170px!important}
.ml-180{margin-left:180px!important}
.ml-190{margin-left:190px!important}
.ml-200{margin-left:200px!important}
.ml-210{margin-left:210px!important}
.ml-220{margin-left:220px!important}
.ml-230{margin-left:230px!important}
.ml-240{margin-left:240px!important}
.ml-250{margin-left:250px!important}

.ml-10_prc{margin-left:10% !important}
.ml-15_prc{margin-left:15% !important}
.ml-20_prc{margin-left:20% !important}
.ml-25_prc{margin-left:25% !important}
.ml-30_prc{margin-left:30% !important}
.ml-40_prc{margin-left:30% !important}
.ml-50_prc{margin-left:30% !important}

.mt-0{margin-top:0!important}
.mt-5{margin-top:5px!important}
.mt-10{margin-top:10px!important}
.mt-15{margin-top:15px!important}
.mt-20{margin-top:20px!important}
.mt-25{margin-top:25px!important}
.mt-30{margin-top:30px!important}
.mt-40{margin-top:40px!important}
.mt-50{margin-top:50px!important}
.mt-60{margin-top:60px!important}
.mt-70{margin-top:70px!important}
.mt-80{margin-top:80px!important}
.mt-90{margin-top:90px!important}
.mt-100{margin-top:100px!important}
.mt-120{margin-top:120px!important}
.mt-130{margin-top:130px!important}
.mt-140{margin-top:140px!important}
.mt-150{margin-top:150px!important}
.mt-200{margin-top:200px!important}

.mt--5{margin-top: -5px!important}
.mt--10{margin-top: -10px!important}
.mt--15{margin-top: -15px!important}
.mt--20{margin-top: -20px!important}

.mb-0{margin-bottom:0px!important}
.mb-3{margin-bottom:3px!important}
.mb-5{margin-bottom:5px!important}
.mb-10{margin-bottom:10px!important}
.mb-15{margin-bottom:15px!important}
.mb-20{margin-bottom:20px!important}
.mb-25{margin-bottom:25px!important}
.mb-30{margin-bottom:30px!important}
.mb-40{margin-bottom:40px!important}
.mb-50{margin-bottom:50px!important}

.mr-0{margin-right:0 !important}
.mr-5{margin-right:5px!important}
.mr-10{margin-right:10px!important}
.mr-15{margin-right:15px!important}
.mr-20{margin-right:20px!important}
.mr-30{margin-right:30px!important}
.mr-40{margin-right:40px!important}
.mr-50{margin-right:50px!important}
.mr-60{margin-right:60px!important}

.mr--5{margin-right:-5px!important}
.mr--10{margin-right:-10px!important}
.mt--5{margin-top:-5px!important}
.mt--10{margin-top:-10px!important}


/* --- Paddings --- */
/*.p-0{padding:0!important}
.p-5{padding:5px!important}
.p-10{padding:10px!important}
.p-20{padding:20px!important}
.p-30{padding:30px!important}
.p-35{padding:35px!important}
.p-40{padding:40px!important}
.p-50{padding:50px!important}*/

.pr-0{padding-right:0 !important}
.pr-5{padding-right:5px !important}
.pr-10{padding-right:10px !important}
.pr-20{padding-right:20px !important}
.pr-30{padding-right:30px !important}
.pr-40{padding-right:40px !important}
.pr-50{padding-right:50px !important}

.pl-0{padding-left:0!important}
.pl-5{padding-left:5px!important}
.pl-10{padding-left:10px!important}
.pl-20{padding-left:20px!important}
.pl-30{padding-left:30px!important}
.pl-40{padding-left:40px!important}
.pl-50{padding-left:50px!important}
.pl-60{padding-left:60px!important}
.pl-70{padding-left:70px!important}
.pl-80{padding-left:80px!important}
.pl-90{padding-left:90px!important}
.pl-100{padding-left:100px!important}

.pt-0{padding-top:0!important}
.pt-5{padding-top:5px!important}
.pt-9{padding-top:9px!important}
.pt-10{padding-top:10px!important}
.pt-15{padding-top:15px!important}
.pt-20{padding-top:20px!important}
.pt-30{padding-top:30px!important}
.pt-40{padding-top:40px!important}
.pt-50{padding-top:50px!important}
.pt-60{padding-top:60px!important}

.pb-0{padding-bottom:0!important}
.pb-5{padding-bottom:5px!important}
.pb-10{padding-bottom:10px!important}
.pb-15{padding-bottom:15px!important}
.pb-20{padding-bottom:20px!important}
.pb-30{padding-bottom:30px!important}
.pb-40{padding-bottom:40px!important}
.pb-50{padding-bottom:50px!important}

/* --- Width --- */
.width-10{width:10%!important}
.width-15{width:15%!important}
.width-20{width:20%!important}
.width-25{width:25%!important}
.width-30{width:30%!important}
.width-33{width:33%!important}
.width-35{width:35%!important}
.width-40{width:40%!important}
.width-50{width:50%!important}
.width-60{width:60%!important}
.width-70{width:70%!important}
.width-80{width:80%!important}
.width-90{width:90%!important}
.width-95{width:95%!important}
.width-100{width:100%!important}

.w-5{width:5px!important}
.w-10{width:10px!important}
.w-15{width:15px!important}
.w-20{width:20px!important}
.w-25{width:25px!important}
.w-30{width:30px!important}
.w-35{width:35px!important}
.w-40{width:40px!important; min-width:40px!important }
.w-45{width:45px!important}
.w-50{width:50px!important}
.w-60{width:60px!important}
.w-70{width:70px!important}
.w-80{width:80px!important}
.w-90{width:90px!important}
/*.w-100{width:100px!important}*/
.w-120{width:120px!important}
.w-130{width:130px!important}
.w-150{width:150px!important}
.w-160{width:160px!important}
.w-170{width:170px!important}
.w-200{width:200px!important}
.w-220{width:220px!important}
.w-230{width:230px!important}
.w-240{width:240px!important}
.w-250{width:250px!important}
.w-300{width:300px!important}
.w-350{width:350px!important}
.w-400{width:400px!important}
.w-450{width:450px!important}
.w-500{width:500px!important}
.w-550{width:550px!important}
.w-600{width:600px!important}
.w-650{width:650px!important}
.w-700{width:700px!important}
.w-750{width:750px!important}
.w-800{width:800px !important}
.w-850{width:850px !important}

.min-w-5{min-width:5px!important}
.min-w-10{min-width:10px!important}
.min-w-15{min-width:15px!important}
.min-w-20{min-width:20px!important}
.min-w-30{min-width:30px!important}
.min-w-40{min-width:40px!important}
.min-w-50{min-width:50px!important}
.min-w-60{min-width:60px!important}
.min-w-70{min-width:70px!important}
.min-w-80{min-width:80px!important}
.min-w-90{min-width:90px!important}
.min-w-100{min-width:100px!important}
.min-w-120{min-width:120px!important}
.min-w-130{min-width:130px!important}
.min-w-150{min-width:150px!important}
.min-w-200{min-width:200px!important}
.min-w-250{min-width:250px!important}
.min-w-300{min-width:300px!important}
.min-w-350{min-width:350px!important}
.min-w-400{min-width:400px!important}
.min-w-450{min-width:450px!important}
.min-w-500{min-width:500px!important}

.max-w-60{max-width:60px!important}
.max-w-80{max-width:80px!important}
.max-w-100{max-width:100px!important}
.max-w-200{max-width:200px!important}
.max-w-250{max-width:250px!important}
.max-w-300{max-width:300px!important}
.max-w-400{max-width:400px!important}
.max-w-500{max-width:500px!important}
.max-w-550{max-width:550px!important}
.max-w-600{max-width:600px!important}
.max-w-750{max-width:750px!important}
.max-w-800{max-width:800px!important}
.max-w-850{max-width:850px!important}
.max-w-900{max-width:900px!important}
.max-w-950{max-width:950px!important}
.max-w-1000{max-width:1000px!important}

.max-h-100{max-height:100px!important}
.max-h-150{max-height:150px!important}
.max-h-200{max-height:200px!important}
.max-h-300{max-height:300px!important}
.max-h-400{max-height:400px!important}
.max-h-500{max-height:500px!important}
.max-h-550{max-height:550px!important}
.max-h-600{max-height:600px!important}
.max-h-750{max-height:750px!important}
.max-h-800{max-height:800px!important}
.max-h-850{max-height:850px!important}
.max-h-900{max-height:900px!important}
.max-h-950{max-height:950px!important}
.max-h-1000{max-height:1000px!important}

.min-h-5{min-height:5px!important}
.min-h-10{min-height:10px!important}
.min-h-15{min-height:15px!important}
.min-h-20{min-height:20px!important}
.min-h-25{min-height:25px!important}
.min-h-30{min-height:30px!important}
.min-h-40{min-height:40px!important}
.min-h-50{min-height:50px!important}
.min-h-60{min-height:60px!important}
.min-h-70{min-height:70px!important}
.min-h-80{min-height:80px!important}
.min-h-90{min-height:90px!important}
.min-h-100{min-height:100px!important}
.min-h-120{min-height:120px!important}
.min-h-150{min-height:150px!important}
.min-h-200{min-height:200px!important}
.min-h-250{min-height:250px!important}
.min-h-300{min-height:300px!important}

.mh-100{min-height:100px!important}
.mh-200{min-height:2100px!important}
.mh-300{min-height:300px!important}
.mh-400{min-height:400px!important}
.mh-500{min-height:500px!important}
.mh-600{min-height:600px!important}

.h-5{height:5px!important}
.h-10{height:10px!important}
.h-20{height:20px!important}
.h-30{height:30px!important}
.h-40{height:40px!important}


/* --- Font --- */
.font-1rem{font-size: 1rem!important}
.font-2rem{font-size: 2rem!important}
.font-3rem{font-size: 3rem!important}
.font-4rem{font-size: 4rem!important}
.font-5rem{font-size: 5rem!important}

.font-10{font-size:10px!important}
.font-11{font-size:11px!important}
.font-12{font-size:12px!important}
.font-13{font-size:13px!important}
.font-14{font-size:14px!important}
.font-16{font-size:16px!important}
.font-18{font-size:18px!important}
.font-20{font-size:20px!important}
.font-22{font-size:22px!important}
.font-24{font-size:24px!important}
.font-28{font-size:28px!important}
.font-32{font-size:32px!important}
.font-36{font-size:36px!important}
.font-38{font-size:38px!important}
.font-46{font-size:46px!important}
.font-48{font-size:48px!important}
.font-52{font-size:52px!important}
.font-56{font-size:56px!important}
.font-64{font-size:64px!important}
.font-72{font-size:72px!important}
.font-84{font-size:84px!important}
.font-100{font-size:100px!important}
.font-110{font-size:110px!important}
.font-120{font-size:120px!important}
.font-130{font-size:130px!important}
.font-140{font-size:140px!important}
.font-150{font-size:150px!important}
.font-160{font-size:160px!important}
.font-170{font-size:170px!important}
.font-180{font-size:180px!important}
.font-190{font-size:190px!important}
.font-200{font-size:200px!important}
.font-400{font-size:400px!important}

.font-16-em{font-size:1.6em !important}

.font-normal{font-weight:normal!important}
.strong{font-weight:bolder !important}
.text-center{text-align:center!important}
.text-left{text-align:left!important}
.text-right{text-align:right!important}
.underline{text-decoration: underline}
.font-italic{font-style:italic !important}

 /* Color */
.color-1{color:#111!important}
.color-2{color:#222!important}
.color-3{color:#333!important}
.color-4{color:#444!important}
.color-5{color:#555!important}
.color-6{color:#666!important}
.color-7{color:#777!important}
.color-8{color:#888!important}
.color-9{color:#999!important}
.color-0{color:#000!important}

.color-c{color:#ccc!important}
.color-d{color:#ddd!important}
.color-f{color:#fff!important}

.color-green{color:green!important}
.color-red{color:red!important}
.color-white{color:#fff!important}
.color-gray{color:gray!important}
.color-blue{color:#00c0ef!important}

.color-primary{color:#4361ee !important}
.color-info{color:#f39c12 !important}
.color-success{color:#26c49f !important}
.color-warning{color:#2196f3 !important}
.color-danger{color:#e7515a !important}

.font-weight-300{font-weight:300 !important}
.font-weight-400{font-weight:400 !important}
.font-weight-500{font-weight:500 !important}
.font-weight-600{font-weight:600 !important}
.font-weight-700{font-weight:700 !important}

.line-height-05{line-height: 0.5em !important}
.line-height-06{line-height: 0.6em !important}
.line-height-07{line-height: 0.7em !important}
.line-height-08{line-height: 0.8em !important}
.line-height-09{line-height: 0.9em !important}
.line-height-1{line-height: 1em !important}
.line-height-12{line-height: 1.2em !important}
.line-height-13{line-height: 1.3em !important}
.line-height-14{line-height: 1.4em !important}
.line-height-18{line-height: 1.8em !important}
.line-height-2{line-height: 2em !important}
.line-height-25{line-height: 2.5em !important}
.line-height-3{line-height: 3em !important}

.error{
    color:red;
    font-size:12px!important;
}

.text-white-shadow{
    text-shadow: 0 1px 0 #FFFFFF;
}

/* --- Blocks --- */
.block{
    display:block;
    float:left;
}
.inline-block{
    display:inline-block !important;
}

img:not(.fixed-size){
    width: auto;
    height: auto;
    max-width: 100%;
}

.shadow{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uppercase{text-transform: uppercase !important}

/* --- Backgrouns --- */
.white-bkg, .bkg-white{background: #fff!important}
.no-bkg{background:none !important}
.gray-bkg{background: #ccc!important}
.bkg9{background: #999!important}
.bkg7{background: #777!important}
.bkg6{background: #666!important}
.bkgE{background: #eee!important}
.bkgC{background: #ccc!important}
.bkgD{background: #ddd !important}

.bkg-9{background: #999!important}
.bkg-7{background: #777!important}
.bkg-6{background: #666!important}
.bkg-e{background: #eee!important}
.bkg-c{background: #ccc!important}
.bkg-d{background: #ddd !important}
.bkg-f{background: #fff !important}
.bkg-f2{background: #f2f2f2 !important}
.bkg-f5, tr.bkg-f5 > td{background: #f5f5f5 !important}

/* --- Cursor --- */
.pointer{cursor:pointer !important}
.cursor-pointer{cursor:pointer !important}
.cursor-default{cursor:default !important}

/* --- Positions --- */
.relative{position:relative !important}

/* --- Tables --- */
.table-default{
    border:none !important;
    background: none !important;
}
.table-default th,
.table-default td{
    border:none !important;
    background: none !important;
}

.no-border{border:none !important}
.bordered {
    border: 1px solid #ddd !important;
}


.opacity-20 {
    opacity: 0.2;
    filter: alpha(opacity=20); /* For IE8 and earlier */
}
.opacity-50 {
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}
.opacity-80 {
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
}

.uppercase{
    text-transform: uppercase;
}
.lowercase{
    text-transform: lowercase !important;
}


/*input::-webkit-input-placeholder {
    font-size:12px;
}
input:-moz-placeholder {
    font-size:12px;
}*/

.vertical-align-top{vertical-align: top !important}
.vertical-align-middle{vertical-align: middle !important}
.vertical-align-bottom{vertical-align: bottom !important}


.list-style-disc{list-style-type:disc !important}
.list-style-circle{list-style-type:circle !important}
.list-style-square{list-style-type:square !important}
.list-style-none{list-style-type:none !important}


.line-through{text-decoration:line-through !important}


.table-row {
    display: table-row;
}
.table-cell {
    display: table-cell;
}

.row.table-row {
    display: table-row;
}
.row.table.row > div {
    display: table-cell;
    vertical-align: middle;
    float:none;
}

.z-index-999{
    z-index:999 !important;
}
.z-index-9999{
    z-index:9999 !important;
}

/* END MAIN CORRECTION STYLES */

