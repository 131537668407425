@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body {
    background-color: #fff;
    font-size: 1.1rem;
}

.alert-slim {
    padding: .5rem;
}

.btn-default {
    color: #0E2E50;
    background-color: #eee;
    border-color: #ddd;
}

.navbar-brand img {
    width: 3em !important;
    height:3em !important;
}

.navbar-brand h3 {
    font-size: 2em;
}
.navbar-brand h3 span {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-left: 0;
}

.dropdown-item {
    padding: 0.7rem 1rem;
}

.page-header {
    padding-top: 3rem !important;
    padding-bottom: 2rem !important;
}

.header-inner {
    padding-top: 60px;
    margin-top: 3rem !important;
    margin-bottom: 2rem !important;
}

.text-info {
    color:#6da7cf !important;
}

.filter-green{
    filter: brightness(0) saturate(100%) invert(44%) sepia(92%) saturate(576%) hue-rotate(121deg) brightness(101%) contrast(102%);
}

.nav-bar {
    margin-top: 30px;
}

.navbar-brand .icon {
    border: none !important;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "FontAwesome";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 5px;
    transition: .5s;
}

.account-base {
    padding-top: 100px;
}

.nav-bar.account-nav {
    position: fixed;
    padding: 0;
    z-index: 9999;
    margin-top: 0;
    opacity: 1 !important;
}

.owl-dots {
    display: none;
}

.job-item {
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 0 45px rgb(0 0 0 / 8%);
    transition: .5s;
    padding: 1.5rem;
}
.job-item:hover {
    border-color: rgba(0, 0, 0, .08);
    box-shadow: none;
}

.nav-pills .nav-item .active {
    border-bottom: 2px solid var(--primary);
}


.nav-bar {
    opacity: .9;
}

.nav-bar.sticky-top ,
.nav-bar:hover{
    opacity: 1 !important;
}
.navbar-toggler {
    margin-bottom: 10px;
}

.form-wrapper {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgb(0 0 0 / 8%);
}
.form-wrapper label {
    font-weight: 600;
}
.form-floating label,
.form-check label {
    font-size: 1em;
    font-weight: inherit;
}
.form-select-lg {
    font-size: 1rem;
}

.extra-progress-wrapper .progress {
    width: 100% !important;
}

.select2-container--bootstrap-5 .select2-selection {
    font-size: 1em !important;
}

img.border {
    border: 6px solid #dee2e6 !important;
}

.avatar {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    object-fit: cover;
}

#map {
    height: 500px;
    width: 100%;
    margin: 0px 12px 18px 12px;
    position: relative;
}


.rect-img-container {
  position: relative;
}

.rect-img-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rect-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#scrollToContactsBtn {
    display:none;
}

.form-label {
    margin-bottom: 0.1rem !important;
    font-size: 1.25rem !important;
}

label.required:after {
    content: " *";
    color: #e7515a !important;
}

.catalog-bage {
    position: absolute;
    top: 20px;
    left: 20px;
}

/* --------- trix-editor --------- */
.trix-button--icon-strike,
.trix-button--icon-code,
.trix-button--icon-decrease-nesting-level,
.trix-button--icon-increase-nesting-level {
    display: none;
}
trix-editor {
    min-height: 140px;
}

trix-toolbar .trix-button-group.trix-button-group--file-tools,
.trix-button--icon-attach,
.trix-button-group.trix-button-group--block-tools,
.trix-button--icon-heading-1,
.trix-button--icon-bullet-list,
.trix-button--icon-number-list,
.trix-button--icon-quote {
    display: none;
}

/* --------- end trix-editor --------- */

 

.file-preview-wrapper {
    
}
.delete-file-btn {
    position: absolute;
    top: -10px;
    right: -15px;
}

.file-preview-wrapper .file-item {
    position: relative;
    display: block;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    border: 1px solid transparent;
    transition: .5s;
    padding: 8px;
    margin-bottom: 10px;
    max-width: 150px;
    max-height: 150px;
}
.file-preview-wrapper .file-item:hover {
    border-color: rgba(0, 0, 0, .08);
    box-shadow: none;
}
.file-preview-wrapper .file-item > a > img{
    max-height: 140px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.dropzone {
    width: 100% !important;
    padding-top: 10px;
}


#upload_chunked_file {
    display: none;
}
.custom-file-upload {
    width: 100%;
    height: 130px;
    border: 4px dashed rgb(204, 204, 204);
    color: rgb(204, 204, 204);
    text-align: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    z-index: 100;
    cursor: default;
    padding-top: 10px;
}


.my-dropzone {
    background-color: #eee;
    height: 200px;
    border: 4px dashed rgb(204, 204, 204);
    color: rgb(204, 204, 204);
    margin-bottom: 30px;
}

#detailsWrapper h1 {
    font-size: 2rem;
}

a > .step-button.active > i,
a > .step-button.done > i {
    color: #fff
}
a > .step-button > i {
    color: #666565
}

.flash-messages-wrapper .alert {
    opacity: .6;
}
.flash-messages-wrapper .alert:hover {
    opacity: 1;
}

.lotIdWrapper {
    position: absolute;
    top: -20px;
    right: -10px;
}

.edit-page-informer ul > li {
    margin-bottom: 10px;
}
.modal-title {
    font-weight: 300 !important;
}

.module-edit-link {
    position: absolute;
    z-index: 9999;
    background-color: #0E2E50;
    padding: 5px 10px;
    border-radius: 50%;
    opacity: 20%;
}
.module-edit-link:hover {
    opacity: 100%;
}


.ui-autocomplete {
    max-height: 300px !important;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
}

.landmark-data {
    margin-bottom: .3em;
    line-height: 1em;
}
.landmark-data img {
    width: 25px;
    margin-bottom: 5px;
    margin-right: -4px;
}


@media (max-width: 991px){

/*    .page-container {
        margin-top: 10px;
    }*/

    .nav-bar {
        margin-top: 0;
    }

    .navbar-brand h3 {
        font-size: 1.2em;
    }

    .navbar-brand img {
        width: 1.8em !important;
        height:1.8em !important;
    }


    .page-header {
        padding-top: .6rem !important;
        padding-bottom: .4rem !important;
    }

    .header-inner {
        padding: 0 !important;
    }

    #map {
        height: 300px;
        width: 95%;
        margin: 0px 12px 18px 12px;
        position: relative;
    }

    #scrollToContactsBtn {
        display:block;
    }

    .landmark-data {
        display: block;
    }

    .job-item {
        border: 1px solid transparent;
        border-radius: 2px;
        box-shadow: 0 0 45px rgb(0 0 0 / 8%);
        transition: .5s;
        padding: .5rem .1rem;
    }

    .lotIdWrapper {
      position: absolute;
      top: -20px;
      right: 15px;
    }

}