.steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
}
.step-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: #ccc;
    transition: .4s;
}
.step-button.active {
    width: 60px;
    height: 60px;
    background-color: #00B98E;
    color: #fff;
}
.step-button.done {
    background-color: #00B98E;
    color: #fff;
}
.step-item {
    z-index: 10;
    text-align: center;
}

.step-button i {
    font-size: 1.8em;
    margin-top: .3em;
}

.step-button.active i {
    font-size: 2em;
    margin-top: .35em;
}

#progress {
  -webkit-appearance:none;
    position: absolute;
    width: 92%;
    z-index: 5;
    height: 5px;
    margin-left: 18px;
    margin-bottom: 0;
}
 
/* правим стили прогресс-бара */
#progress::-webkit-progress-value {
    background-color: #00B98E;
    transition: .5s ease;
}
#progress::-webkit-progress-bar {
    background-color: #ccc;
}